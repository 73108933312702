import React, { FC } from 'react';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';

const { Panel } = Collapse;

const SkillSimulator: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Collapse>
        <Panel key="1" header="Stella Skill Simulator">
          <div className="text-center">
            <h1>
              <a href="/[course]Stella Skill Simulator v4.lr2crs">
                {formatMessage({ id: 'skillPage.stellaTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1fqQytr-6t1qng6GyTQU2Fy8uwaO9jAYrqdUZORbnfUM/edit?gid=447049147#gid=447049147">
                {formatMessage({ id: 'skillPage.stellaDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="st0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>The NightScape [marienother]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Elina (feat. Reislaufer) [INFERNO]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>HEXPERUS [NEXTAGE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>reAdvenTia [Pentagon]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="st1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>carnation [for expert]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Lucid Dreaming [キャワ]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>リクロスフェイト [Nuclear]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>水晶世界 ～Fracture～ [MANIAC]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="st2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Life is PIANO [Wander]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>adabana [HEARTFUL]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ハッピーフェイクショー [Haven't you heard?]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>"Flanker-E" -Xe-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="st3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>ハートマークとエンドロール [Finale]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>BIG LOVE [HEARTFUL]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>星月トラジコメディー [GALAXY]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Endless Dream -Reminiscence-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="st4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Rechronize [DEPARTURE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Lethal Strike [DENIAL]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ルーネイトエルフ～霧の湖の物語～ [Pentagon]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Simurgh [yumether]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="st5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>サウンドコラージュ・ダイスキズム [Not-Daisuki]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Rabbits [Maryther]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>The Mighty A [NIGHTMARE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>whither [douvenonsnousquesommesnousouallonsnous]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="st6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>マッドメチャクチャニズム [Crushing]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Breeze -SUMMER BOSS-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Marmot [BUSTER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ETERNiZE [ETERNiTY]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="st7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Sylph (SP MARYTHER)</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Overdrive Power [Earth Power]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>スナレ</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>stardust explorer [starlight express]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="st8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>爽昧夢想ラバーズ [NOCTAMBULISM]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>DUARRIER [Twin Stream+]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Blue Delaygoon [フルマラソン]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Stellaria [LAST VOYAGE]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="st9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Apocalypse Nemesis -Meteor Noise- [Catastrophe]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Weaponhuman [Armageddon]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Terpsichore [Other]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>!nterroban(?, [SUPERFACTORIAL]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="st10">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st10</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Blue-White Crazybits [GOD]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Quota  -Falsen-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Sesame Kingdom [HEARTLESS]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>BEYOND [Sprint]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="st11">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st11</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>花嵐、空に溶けて [虚]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Avantgarde [Oripathy]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>夜騒曲 op.Q-ν [Nightmare]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Celestial Axes [Other]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="13" header="st12">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st12</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>black opal [Pentagon++]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Upside-down</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>MESMERA [KOOKY]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>inertia [///]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
        <Panel key="2" header="Satellite Skill Analyzer">
          <div className="text-center">
            <h1>
              <a href="/[course]Satellite Skill Analyzer v2.lr2crs">
                {formatMessage({ id: 'skillPage.satelliteTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1SJbO7hXCSpGR4DEJErZV5bqDS-4KbcEQnR4Oryog1nk/edit?usp=sharing">
                {formatMessage({ id: 'skillPage.satelliteDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="sl0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>翼の折れた天使たちのレクイエム [Another]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>アトロポスと最果の探求者</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Life is PIANO [Another]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>BLAZE YOUR SOUL [SP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="sl1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IKAROS [HARD]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Berry Go!! [Esteem and Love]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Latch (Flip-Flop) [Spinner+]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Brave My Soul -Flareness Heart-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="sl2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Sea Breeze Memory -Summer Vacation-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Apocaliptix [Doomsayer]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Love☆Spellcard [ZOI]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Re/Im [ZOI]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="sl3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>MAGENTA POTION [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Wicked Laboratory</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>GLITCH SWITCH [SP INSANE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Disorder -ANOTHER-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="sl4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>EVERLASTING HAPPiNESS↑ [ZOI]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Mr. BLACKRUM [SP RUNOTHER -Cuba Libre-]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Transfixation [STERRITE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>squartatrice [HARD]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="sl5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Signager -HONGKONG EXPRESS_FIX-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>banana man [HARD]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Wizdomiot [ABNORMAL]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Ground Z.E.R.O. [ZOI]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="sl6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Awaking Beat-From the next generation- [W]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>平衡世界 Parallel World [HARDEST]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Absolute Future [ANOTHER+]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>白菊 [Snother]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="sl7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Remembrance [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Astronomical Twilight [Sunrise]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Doppelganger [Eb]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>energy night [JOY]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="sl8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Happiness Magical Kanon [HAPPY]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ラケシスと永遠の祈祷者 [Sanctuary]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Empress of Raizze [ストレッチ]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>DESPERATE -Last Battle-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="sl9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Free Hand zero [ANOTHER*]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Andro [F]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>永劫綺譚の純潔葬花 -Red Lily-</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ニニ - ニサシゴ -</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="sl10">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl10</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>踊る水飛沫 [KF7]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Central Nucleus [INFERNO]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>温泉大作戦 [温泉名人]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Andromeda [yumether]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="sl11">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl11</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>SYNTHETICS [CYBERTIC ETUDE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Wavetapper [ATHLETIC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Death Opera [The Phantom]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ヒメタイプ [LAPITHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="13" header="sl12">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl12</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Sparkling☆Sunshine!! [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ting ting [belong]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Total Eclipse of the Sun -Nightmare-</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Terpsichore [Li]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
        <Panel key="3" header="DP Satellite Skill Analyzer">
          <div className="text-center">
            <h1>
              <a href="/[course]DP Satellite Skill Analyzer.lr2crs">
                {formatMessage({ id: 'skillPage.dpsatelliteTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1BiekRtFgWsJG4Uh7-cFR9kO8j4GIchUtVkf5ZYFjY7Q/edit#gid=1856418955">
                {formatMessage({ id: 'skillPage.dpsatelliteDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="DPsl0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Estella [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>MANHATTAN [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Postliminium [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ノーザンブルー・デイブリーズ [DP-sagANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="DPsl1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>黄昏のメロディア [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>A.O. [DP]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Is This Your Memory? [Another]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Prismrose [DP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="DPsl2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Event Horizon [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Blooming [DP HYPER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>XALICE [DP WONDERLAND]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Good Life [y_phANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="DPsl3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>☆ウィンドサンバランド☆ -DP ANOTHER-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>雪肌玉惚少女 [DP Another]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Central Nucleus [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>The Crack of Dawn [SUNRISE]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="DPsl4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>YASAKANI [DP INSANE+]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Eisenschweif (DPA)</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Black or White</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Midnight o'clock [sub another]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="DPsl5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>リリカと魔法の言葉 [DP Lyrical]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Granblaze [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>F4C4D3 [DPA]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>浮世絵横丁</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="DPsl6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>PPBQ</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>PLASTIC GIRLS (ft. Seorryang) [DP MANIAC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Chaotic☆Dream★Festival [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Old Pal</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="DPsl7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Emptiness [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Summer White [DP NOSTALGIC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Divided Montage [14KEYS ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Ladymade corestar [14kumanother]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="DPsl8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IN [HAORSE MUSIC / DP譜面]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Dreamy Fall [DP INSANE]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>witchcraft [14A]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Evanescent [DP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="DPsl9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Yorixiro [OyaxiroSama]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>DELIGHTS -EXTREME14-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>XVOTO</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Kaleidoscope [DP_Li]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="DPsl10 (DPst0)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl10 (DPst0)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IKAROS [DP Nymph]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>現実をカットアップしてみた [DPINSA.NE]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Danse de Romani</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ELIMINATOR [DP INSANE]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="DPsl11 (DPst1)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl11 (DPst1)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>#be_fortunate [DPYoitherEASY]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>new earth -dp stella-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ALICE IN PICOPICO NIGHT (DP ANOTHER)</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>夜間飛行トラベラー [DP MANIAC]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="13" header="DPsl12 (DPst2)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl12 (DPst2)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Apollo [1970.04.11]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ハードルネッサンバ -DP MID BOSS-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>take me to the moon [DP Disaster]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>WHIRLWIND [Twin Blast]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default SkillSimulator;
