/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
  Popover,
} from 'antd';
import { InboxOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import diff from '../../utils/diff';
import levelArray from '../../utils/levelArray';
import getTableName from '../../utils/getTableName';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import { postRequest } from '../../utils/ajax';
import { Link } from 'react-router-dom';

interface MatchProps {
  table: string;
}

interface SongDataProps {
  md5: string;
  sha256: string;
  title: string;
  artist: string;
  url: string;
  url_diff: string;
}

interface OriginProps {
  md5: string;
  sha256: string;
}

const { Item } = Form;
const { Option } = Select;
const { Dragger } = Upload;

const defaultOriginProps: OriginProps = {
  md5: '',
  sha256: '',
};

const defaultSongDataProps: SongDataProps = {
  md5: '',
  sha256: '',
  title: '',
  artist: '',
  url: '',
  url_diff: '',
};

const arrayBufferToWordArray = (ab: ArrayBuffer) => {
  const i8a = new Uint8Array(ab);
  const a = [];
  for (let i = 0; i < i8a.length; i += 4) {
    a.push(
      // eslint-disable-next-line
      (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3],
    );
  }
  return [a, i8a.length];
};

const listStyle: React.CSSProperties = {
  listStyleType: 'disc',
  listStylePosition: 'inside',
};

const Submit: React.FC<MatchProps> = ({ table }) => {
  const [form] = Form.useForm();
  const user = useRecoilValue(userState);
  const [zure, setZure] = useState(-1);
  const [activeZureVerification, setActiveZureVerification] = useState<boolean | undefined>(false);
  const [adultContent, setAdultContent] = useState<boolean | undefined>(false);
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [originFileList, setOriginFileList] = useState<UploadFile<any>[]>([]);
  const [songData, setSongData] = useState<SongDataProps>(defaultSongDataProps);
  const [originSongData, setOriginSongData] = useState<OriginProps>(defaultOriginProps);
  const [submitDisable, setSubmitDisable] = useState(false);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const info = (values: any) => {
    Modal.confirm({
      title: (
        <span>
          {`(${getTableName(table)}) ${formatMessage({
            id: 'votePage.modalVoteTitle',
          })}`}
        </span>
      ),
      content: (
        <span>
          <ul style={listStyle}>
            <li style={{ color: 'red' }}>{formatMessage({ id: 'votePage.modalVotes.0' })}</li>
            <li>{formatMessage({ id: 'votePage.modalVotes.1' })}</li>
            {table !== 'fr' ? (
              <>
                <li>
                  {formatMessage({ id: 'votePage.modalVotes.2' })}
                  <a href="https://stairway.sakura.ne.jp/smalltools/minibmsplay/diff.htm">
                    {formatMessage({ id: 'votePage.modalVotes.3' })}
                  </a>
                </li>
                <li>{formatMessage({ id: 'votePage.modalVotes.4' })}</li>
              </>
            ) : null}
            <li>{formatMessage({ id: 'votePage.modalVotes.5' })}</li>
          </ul>
        </span>
      ),
      async onOk() {
        setSubmitDisable(true);
        const result = await postRequest('/api/vote/submit', {
          songData: { ...songData, ...values },
          originSongData,
          zureVerification: zure === 0,
          table,
        });

        if (!result.success) {
          if (result.error) {
            message.error(formatMessage({ id: `error.${result.error}` }));
          } else message.error('Unknown Error.');
          setSubmitDisable(false);
        } else {
          message.success('OK');
          history.go(0);
        }
      },
    });
  };

  const onChange = (fileInfo: UploadChangeParam<UploadFile>) => {
    if (fileInfo.file.status !== 'removed') setFileList([fileInfo.file]);
    if (fileInfo.fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result instanceof ArrayBuffer) {
          setSubmitDisable(true);
          const result = await postRequest(
            '/api/vote/get-file-data',
            arrayBufferToWordArray(event.target.result),
          );
          if (!result.success) {
            if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
            else message.error('Unknown Error.');
          } else {
            setSongData({ ...result.json });
          }
          setSubmitDisable(false);
        }
      };
      reader.readAsArrayBuffer(fileInfo.file as unknown as Blob);
    }
  };

  const onRemove = () => {
    setFileList([]);
    setSongData(defaultSongDataProps);
  };

  const onOriginChange = (fileInfo: UploadChangeParam<UploadFile>) => {
    if (fileInfo.file.status !== 'removed') setOriginFileList([fileInfo.file]);
    if (fileInfo.fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result instanceof ArrayBuffer) {
          setSubmitDisable(true);
          const result = await postRequest(
            '/api/vote/get-hash',
            arrayBufferToWordArray(event.target.result),
          );
          if (!result.success) {
            if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
            else message.error('Unknown Error.');
          } else {
            setOriginSongData({ ...result });
          }
          setSubmitDisable(false);
        }
      };
      reader.readAsArrayBuffer(fileInfo.file as unknown as Blob);
    }
  };

  const onOriginRemove = () => {
    setOriginFileList([]);
    setOriginSongData(defaultOriginProps);
  };

  useEffect(() => {
    setFileList([]);
    setOriginFileList([]);
    setSongData(defaultSongDataProps);
    setOriginSongData(defaultOriginProps);
    setZure(-1);
    form.resetFields();
    // eslint-disable-next-line
  }, [form, table]);

  useEffect(() => {
    if (fileList[0] instanceof Blob && originFileList[0] instanceof Blob) {
      diff(fileList[0], originFileList[0]).then((zureCount: any) => {
        if (typeof zureCount === 'number') {
          setZure(zureCount);
          form.setFieldsValue({
            zureCount,
          });
        }
      });
    }
  }, [fileList, form, originFileList]);

  const onFinish = async (values: any) => {
    if (values.comment && values.comment.length > 200) {
      message.error(formatMessage({ id: 'error.TOOLONGCOM' }));
    } else if (
      (table === 'st' && user.restriction < values.level) ||
      (table === 'sl' && user.restriction2 < values.level && user.restriction < 0)
      // || (table === 'dp' && user.restriction4 < values.level)
    ) {
      message.error(formatMessage({ id: 'error.RESTRICTIONLEVEL' }));
    } else if (songData.md5) {
      info(values);
    } else {
      message.error(formatMessage({ id: 'error.ESONGNOTFOUND' }));
    }
  };

  return (
    <Form
      form={form}
      labelCol={{
        xs: { span: 24 },
        sm: { span: 8 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 16 },
      }}
      onFinish={onFinish}
    >
      <Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Dragger
          accept=".bme, .bml, .bms"
          beforeUpload={() => false}
          fileList={fileList}
          onChange={onChange}
          onRemove={onRemove}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{formatMessage({ id: 'votePage.clickOrDrag' })}</p>
          <p className="ant-upload-hint">{formatMessage({ id: 'votePage.clickOrDragHint' })}</p>
        </Dragger>
      </Item>
      <Item
        label={formatMessage({ id: 'votePage.submitForm.level' })}
        name="level"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'votePage.submitForm.levelError',
            }),
          },
        ]}
      >
        <Select
          placeholder={formatMessage({
            id: 'votePage.submitForm.levelPlaceholder',
          })}
        >
          {levelArray[table].map((i) => {
            let disabled = false;
            if (table === 'st' && user.restriction < i) {
              disabled = true;
            }
            if (table === 'sl' && user.restriction2 < i && user.restriction < 0) {
              disabled = true;
            }
            if (table === 'dpst' && user.restriction3 < 0) {
              disabled = true;
            }
            if (table === 'dp' && user.restriction4 < i && user.restriction3 < 0) {
              disabled = true;
            }
            return (
              <Option value={i} disabled={disabled} key={i.toString()}>
                {i}
              </Option>
            );
          })}
        </Select>
      </Item>
      <Item label={formatMessage({ id: 'votePage.submitForm.comment' })} name="comment">
        <Input
          placeholder={formatMessage({
            id: 'votePage.comment',
          })}
        />
      </Item>
      {activeZureVerification ? (
        <Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Item>
            <Dragger
              accept=".bme, .bml, .bms, .pms"
              beforeUpload={() => false}
              fileList={originFileList}
              onChange={onOriginChange}
              onRemove={onOriginRemove}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {formatMessage({ id: 'votePage.clickOrDragOrigin' })}
              </p>
              <p className="ant-upload-hint">{formatMessage({ id: 'votePage.clickOrDragHint' })}</p>
            </Dragger>
          </Item>
          <Item
            label={formatMessage({ id: 'votePage.submitForm.zureCount' })}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            name="zureCount"
            rules={[
              {
                required: activeZureVerification,
                message: formatMessage({
                  id: 'votePage.submitForm.zureCountError',
                }),
              },
              {
                max: 0,
                min: 0,
                type: 'number',
                message: (
                  <span>
                    {formatMessage({
                      id: 'votePage.submitForm.zureCountError2',
                    })}
                    <a href="https://stairway.sakura.ne.jp/smalltools/minibmsplay/diff.htm">
                      Click
                    </a>
                  </span>
                ),
              },
            ]}
          >
            <InputNumber readOnly />
          </Item>
        </Item>
      ) : null}
      <Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        }}
      >
        <Button type="primary" htmlType="submit" disabled={submitDisable}>
          {formatMessage({ id: 'votePage.submitButton' })}
        </Button>
      </Item>
      {table !== 'fr' ? (
        <Item>
          <Checkbox
            onChange={(e) => {
              setOriginSongData(defaultOriginProps);
              setOriginFileList([]);
              setZure(-1);
              setActiveZureVerification(e.target.checked);
            }}
            disabled={!songData?.url_diff}
          >
            {formatMessage({ id: 'votePage.zureVerification' })}
          </Checkbox>
        </Item>
      ) : null}
      {user.adult ? (
        <Item>
          <Checkbox
            onChange={(e) => {
              setAdultContent(e.target.checked);
            }}
          >
            {formatMessage({ id: 'votePage.adultContent' })}&nbsp;
            <Popover
              content={
                <span>
                  {formatMessage({ id: 'votePage.adultContentPopover' })}
                  &nbsp;<Link to="/rules">Click</Link>
                </span>
              }
            >
              <span>
                <QuestionCircleFilled />
              </span>
            </Popover>
          </Checkbox>
        </Item>
      ) : null}
      <table className="normal-table" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ width: '100px' }}>MD5</th>
            <td>{songData.md5}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>SHA256</th>
            <td>{songData.sha256}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.0' })}</th>
            <td>{songData.title}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.1' })}</th>
            <td>{songData.artist}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.2' })}</th>
            <td>{songData.url}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.3' })}</th>
            <td>{songData.url_diff}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default Submit;
