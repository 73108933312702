import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Affix, Input, Menu, Table, message, Space } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import qs from 'querystring';
import dayjs from 'dayjs';
import { isUndefined, isNull } from 'util';
import { useIntl } from 'react-intl';
import checkTable from '../utils/checkTable';
import RevisionProps from '../props/revision';
import { postRequest } from '../utils/ajax';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

const { Search } = Input;
const { Item } = Menu;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const RevisionList: React.FC<MatchProps> = ({ location, match }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filteredValue, setFilteredValue] = useState<any>();

  const query = qs.parse(location.search.substring(1));
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<RevisionProps> | SorterResult<RevisionProps>[],
  ) => {
    const setQuery = (field: any, value: any) => {
      if (field && value) {
        queryObject[field] = value;
      }
    };

    const queryObject: any = {};
    const { current: page } = tablePagination;

    if (page) {
      setQuery('page', page);
    }

    if (query.search) {
      setQuery('search', query.search);
    }

    if (Array.isArray(sorter)) {
      const { field, order } = sorter[0];
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    } else {
      const { field, order } = sorter;
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    }

    if (filters.type && filters.type.length > 0) {
      setQuery('typefilter', filters.type.join(','));
      setFilteredValue(filters.type);
    }

    history.push(`${match.params.tableid}?${qs.stringify(queryObject)}`);
  };

  const onSearch = (value: string) => {
    if (value) {
      history.push(`${match.params.tableid}?page=1&search=${value}`);
    }
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/revisionlist', {
        ...query,
        table: match.params.tableid,
      });
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        setTotal(result.total);
        setDataSource([...result.source]);
        if (query.typefilter && typeof query.typefilter === 'string') {
          setFilteredValue(query.typefilter.split(','));
        } else setFilteredValue([]);
      }
      setLoading(false);
    };
    setTotal(0);
    setDataSource([]);
    setFilteredValue([]);
    setLoading(true);
    onTableChange();
    // eslint-disable-next-line
  }, [location.search, match.params.tableid]);

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to="st">STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to="sl">SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to="dpst">DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to="dp">DP SATELLITE</Link>
          </Item>
          <Item key="fr">
            <Link to="fr">EXOPLANET</Link>
          </Item>
        </Menu>
      </Affix>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Search
          placeholder={formatMessage({
            id: 'revisionListPage.searchPlaceholder',
          })}
          defaultValue={typeof query.search === 'string' ? query.search : ''}
          onSearch={onSearch}
          enterButton
          loading={loading}
        />
        <Table<RevisionProps>
          bordered
          scroll={{ x: 720 }}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            defaultCurrent: query.page ? Number(query.page) : 1,
            pageSize: 50,
            showSizeChanger: false,
            total,
          }}
          rowKey="_id"
          size="small"
          onChange={onChange}
          onHeaderRow={() => {
            return {
              style: { backgroundColor: 'white' },
            };
          }}
        >
          <Column<RevisionProps> key="entryNo" title="No" dataIndex="entryNo" sorter width={60} />
          <Column<RevisionProps>
            key="type"
            title={formatMessage({ id: 'revisionListPage.th.0' })}
            dataIndex="type"
            filters={['change', 'delete', 'resurrection', 'transfer'].map((i) => {
              const obj: any = {
                value: i,
              };
              if (i === 'transfer') {
                if (match.params.tableid === 'st' || match.params.tableid === 'dpst') {
                  obj.text = formatMessage({
                    id: `revisionListPage.type.transfer.1`,
                  });
                }
                if (match.params.tableid === 'sl' || match.params.tableid === 'dp')
                  obj.text = formatMessage({
                    id: `revisionListPage.type.transfer.0`,
                  });
              } else obj.text = formatMessage({ id: `revisionListPage.type.${i}` });
              return obj;
            })}
            onFilter={(value, record) => value === record.type}
            defaultFilteredValue={(() => {
              if (typeof query.levelfilter === 'string') return query.levelfilter.split(',');
              if (!isNull(query.levelfilter) && typeof query.levelfilter === 'object')
                return [...query.levelfilter];
              return [];
            })()}
            filteredValue={filteredValue}
            render={(plaintext, record) => {
              if (record.type === 'transfer') {
                if (match.params.tableid === 'st' || match.params.tableid === 'dpst') {
                  return formatMessage({
                    id: `revisionListPage.type.transfer.1`,
                  });
                }
                if (match.params.tableid === 'sl' || match.params.tableid === 'dp')
                  return formatMessage({ id: `revisionListPage.type.transfer.0` });
              }
              return formatMessage({
                id: `revisionListPage.type.${record.type}`,
              });
            }}
            width={100}
          />
          <Column<RevisionProps>
            key="level"
            title="Lv"
            dataIndex="level"
            render={(plaintext, record) =>
              !isUndefined(record.changeTo) ? `${record.level}→${record.changeTo}` : record.level
            }
            width={60}
          />
          <Column<RevisionProps>
            ellipsis
            key="title"
            title={formatMessage({ id: 'revisionListPage.th.1' })}
            dataIndex="title"
            render={(plaintext, record) => {
              return (
                <Link to={`../u/${match.params.tableid}/${record.entryNo}`}>{record.title}</Link>
              );
            }}
            width={240}
          />
          <Column<RevisionProps>
            key="proposer"
            title={formatMessage({ id: 'revisionListPage.th.2' })}
            dataIndex="proposer"
            render={(plaintext, record) =>
              record.voteResult !== 'new' ? (
                record.proposer.userlr2id || record.proposer.minirid ? (
                  <Link
                    to={`/user/${match.params.tableid}/${
                      record.proposer.userlr2id || record.proposer.minirid
                    }`}
                  >
                    {record.proposer.nickname}
                  </Link>
                ) : (
                  record.proposer.nickname
                )
              ) : (
                <span>
                  <LockOutlined />
                  &nbsp;VOTING...
                </span>
              )
            }
            width={100}
          />
          <Column<RevisionProps>
            key="vote"
            title={formatMessage({ id: 'revisionListPage.th.3' })}
            render={(plaintext, record) =>
              record.voteResult !== 'new' ? (
                `${record.voteYes}/${record.voteNeutral}/${
                  record.voteNo
                }(${record.votePercent.toFixed(1)})`
              ) : (
                <span>
                  <LockOutlined />
                  &nbsp;VOTING...
                </span>
              )
            }
            width={100}
          />
          <Column<RevisionProps>
            key="publishedTime"
            title={formatMessage({ id: 'revisionListPage.th.4' })}
            dataIndex="publishedTime"
            render={(plaintext, record) => dayjs(record.publishedTime).format('YYYY/MM/DD')}
            width={100}
          />
        </Table>
      </Space>
    </div>
  );
};

export default RevisionList;
