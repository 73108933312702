import React, { useState, useEffect } from 'react';
import { Affix, Collapse, Menu, Select, Table, message, TablePaginationConfig } from 'antd';
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import qs from 'querystring';
import { useIntl } from 'react-intl';
import StellawayProps from '../props/stellaway';
import checkTable from '../utils/checkTable';
import { postRequest } from '../utils/ajax';
import { SorterResult } from 'antd/lib/table/interface';

const { Panel } = Collapse;
const { Item } = Menu;
const { Option } = Select;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  playerid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const Stellaway: React.FC<MatchProps> = ({ location, match }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const query = qs.parse(location.search.substring(1));
  const history = useHistory();

  const { formatMessage } = useIntl();

  const onPlayerChange = (value: string) => {
    history.push(value);
  };

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<StellawayProps> | SorterResult<StellawayProps>[],
  ) => {
    const setQuery = (field: any, value: any) => {
      if (field && value) {
        queryObject[field] = value;
      }
    };

    const queryObject: any = {};
    const { current: page } = tablePagination;

    if (page) {
      setQuery('page', page);
    }

    if (Array.isArray(sorter)) {
      const { field, order } = sorter[0];
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    } else {
      const { field, order } = sorter;
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    }

    history.push(
      `../${match.params.tableid}/${match.params.playerid}?${qs.stringify(queryObject)}`,
    );
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/stellaway', {
        ...query,
        playerName: match.params.playerid,
        table: match.params.tableid,
      });
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        setCount(result.count);
        setTotal(result.total);
        setDataSource([...result.source]);
      }
      setLoading(false);
    };
    setTotal(0);
    setDataSource([]);
    setLoading(true);
    onTableChange();
    // eslint-disable-next-line
  }, [location.search, match.params.tableid, match.params.playerid]);

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <div>
          <Select
            defaultValue={match.params.playerid}
            style={{ padding: 1, width: '100%' }}
            onChange={onPlayerChange}
          >
            <Option value="lr2">
              <Link to="lr2">Lunatic Rave 2</Link>
            </Option>
            <Option value="beatoraja">
              <Link to="beatoraja">Beatoraja</Link>
            </Option>
          </Select>
          <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
            <Item key="st">
              <Link to={`../st/${match.params.playerid}`}>STELLA</Link>
            </Item>
            <Item key="sl">
              <Link to={`../sl/${match.params.playerid}`}>SATELLITE</Link>
            </Item>
            <Item key="dpst">
              <Link to={`../dpst/${match.params.playerid}`}>DP STELLA</Link>
            </Item>
            <Item key="dp">
              <Link to={`../dp/${match.params.playerid}`}>DP SATELLITE</Link>
            </Item>
          </Menu>
        </div>
      </Affix>
      {match.params.tableid === 'st' || match.params.tableid === 'sl' ? (
        <div className="normal-container">
          <Collapse>
            <Panel key="1" header={formatMessage({ id: 'stellawayPage.skillTableTitle' })}>
              <table className="normal-table">
                <tr>
                  <td className="skill0">sl0</td>
                  <td className="skill1">sl1</td>
                  <td className="skill2">sl2</td>
                  <td className="skill3">sl3</td>
                  <td className="skill4">sl4</td>
                  <td className="skill5">sl5</td>
                  <td className="skill6">sl6</td>
                  <td className="skill7">sl7</td>
                  <td className="skill8">sl8</td>
                  <td className="skill9">sl9</td>
                </tr>
                <tr>
                  <td className="skill10">sl10</td>
                  <td className="skill11">sl11</td>
                  <td className="skill12">sl12</td>
                  <td className="skill13">st0</td>
                  <td className="skill14">st1</td>
                  <td className="skill15">st2</td>
                  <td className="skill16">st3</td>
                  <td className="skill17">st4</td>
                  <td className="skill18">st5</td>
                  <td className="skill19">st6</td>
                </tr>
                <tr>
                  <td className="skill20">
                    st7
                  </td>
                  <td className="skill21">
                    st8
                  </td>
                  <td className="skill22" colSpan={2}>
                    st9
                  </td>
                  <td className="skill23" colSpan={2}>
                    st10
                  </td>
                  <td className="skill24" colSpan={2}>
                    st11
                  </td>
                  <td className="skill25" colSpan={2}>
                    st12
                  </td>
                </tr>
              </table>
            </Panel>
          </Collapse>
        </div>
      ) : (
        <div className="normal-container">
          <Collapse>
            <Panel key="1" header={formatMessage({ id: 'stellawayPage.skillTableTitle2' })}>
              <table className="normal-table">
                <tr>
                  <td className="skill12">sl0</td>
                  <td className="skill13">sl1</td>
                  <td className="skill14">sl2</td>
                  <td className="skill15">sl3</td>
                  <td className="skill16">sl4</td>
                  <td className="skill17">sl5</td>
                  <td className="skill18">sl6</td>
                  <td className="skill19">sl7</td>
                  <td className="skill20">sl8</td>
                  <td className="skill21">sl9</td>
                </tr>
                <tr>
                  <td className="skill22" colSpan={3}>
                    sl10 (st0)
                  </td>
                  <td className="skill23" colSpan={3}>
                    sl11 (st1)
                  </td>
                  <td className="skill24" colSpan={4}>
                    sl12 (st2)
                  </td>
                </tr>
              </table>
            </Panel>
          </Collapse>
        </div>
      )}

      <Table<StellawayProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          defaultCurrent: query.page ? Number(query.page) : 1,
          pageSize: 50,
          showSizeChanger: false,
          total,
        }}
        rowKey={(record) => `record-${record.nickname}`}
        size="small"
        onChange={onChange}
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<StellawayProps> key="userLevel" title="Lv" dataIndex="userLevel" width={40} />
        <Column<StellawayProps>
          key="nickname"
          title={formatMessage({ id: 'stellawayPage.th.0' })}
          dataIndex="nickname"
          render={(plaintext, record) => (
            <span>
              <Link to={`/user/${match.params.tableid}/${record.userlr2id || record.minirid}`}>
                {match.params.tableid === 'st' || match.params.tableid === 'sl' ? (
                  <span className={`skill${record.dan ? record.dan : 'X'}`}>{record.nickname}</span>
                ) : (
                  <span
                    className={`skill${record.dan2 ? (Number(record.dan2) + 12).toString() : 'X'}`}
                  >
                    {record.nickname}
                  </span>
                )}
              </Link>
            </span>
          )}
          width={100}
        />
        <Column<StellawayProps>
          key="userSkill"
          title={formatMessage({ id: 'stellawayPage.th.1' })}
          dataIndex="userSkill"
          sorter
          width={80}
        />
        <Column<StellawayProps>
          key="exp"
          title={formatMessage({ id: 'stellawayPage.th.2' })}
          dataIndex="exp"
          sorter
          width={80}
        />
        {match.params.playerid === 'beatoraja' ? (
          <Column<StellawayProps> key="pfc" title="PFC" dataIndex="pfc" sorter width={80} />
        ) : null}
        <Column<StellawayProps> key="fc" title="FC" dataIndex="fc" sorter width={60} />
        {match.params.playerid === 'beatoraja' ? (
          <Column<StellawayProps> key="exhc" title="EXH" dataIndex="exhc" sorter width={80} />
        ) : null}
        <Column<StellawayProps> key="hc" title="HARD" dataIndex="hc" sorter width={80} />
        <Column<StellawayProps> key="gc" title="CLEAR" dataIndex="gc" sorter width={80} />
        <Column<StellawayProps> key="ec" title="EASY" dataIndex="ec" sorter width={80} />
        {match.params.playerid === 'beatoraja' ? (
          <Column<StellawayProps> key="laec" title="LA-EASY" dataIndex="laec" sorter width={80} />
        ) : null}
        {match.params.playerid === 'beatoraja' ? (
          <Column<StellawayProps> key="aec" title="A-EASY" dataIndex="aec" sorter width={80} />
        ) : null}
        <Column<StellawayProps> key="fa" title="FAILED" dataIndex="fa" sorter width={100} />
        <Column<StellawayProps>
          key="playcount"
          title={formatMessage({ id: 'stellawayPage.th.3' })}
          dataIndex="playcount"
          render={(plaintext, record) => `${record.playcount}/${count}`}
          width={100}
        />
      </Table>
    </div>
  );
};

export default Stellaway;
